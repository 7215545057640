<template>
  <div
    class="login-container bg-ease d-flex align-items-center justify-content-center"
  >
    <v-form
      ref="form"
      v-model="isValid"
      class="form-container pa-9"
      @submit.prevent
    >
      <!-- <v-row no-gutters>
        <v-col class="logo">
          <img class="img-fluid" src="@/assets/images/logo.png">
        </v-col>
      </v-row> -->
      <v-row no-gutters class="mb-9">
        <v-col>
          <div class="text-center text-h4 font-weight-medium white--text">
            {{ $t('signup_immediately') }}
          </div>
        </v-col>
      </v-row>
      <v-row align="center" no-gutters>
        <v-col>
          <v-text-field
            v-model.trim="username"
            autofocus
            solo
            :label="$t('email')"
            :loading="isCheckingExist"
            :rules="rulesUsername"
            validate-on-blur
            @blur="checkUser"
          />
        </v-col>
      </v-row>
      <v-row align="center" no-gutters>
        <v-col>
          <v-text-field
            v-model="password1"
            solo
            dark
            background-color="rgb(197,213,201)"
            :label="$t('password')"
            :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPwd ? 'text' : 'password'"
            @click:append="showPwd = !showPwd"
          />
        </v-col>
      </v-row>
      <v-row align="center" no-gutters>
        <v-col>
          <v-text-field
            v-model="password2"
            solo
            dark
            background-color="rgb(197,213,201)"
            :label="$t('re_password')"
            :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPwd ? 'text' : 'password'"
            @click:append="showPwd = !showPwd"
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="10" offset="1" class="text-center text-h6 pa-2  red--text">
          {{ msg }}
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col class="text-center">
          &nbsp;
        </v-col>
      </v-row>
      <v-row align="center" no-gutters>
        <v-col cols="8" offset="2" class="text-center">
          <v-btn
            rounded
            color="primary"
            :disabled="disableSignup"
            :loading="isLoadingSignup"
            style="width: 100%;height: 50px;"
            @click="signup"
          >
            <div
              class="font-weight-bold text-h5 white--text"
              style="color: rgb(85, 157, 173);"
            >
              {{ $t('identify') }}
            </div>
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        class="mt-3"
        justify="center" align="center"
        no-gutters
      >
        <router-link class="link" :to="{ name: 'Login' }">
          {{ $t('back_to_login') }}
        </router-link>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { validateRequired, validateEmail } from '@/assets/js/validate'

import { apiCommonauthUsers, apiCommonauthAdminUsers } from '@/api'

export default {
  name: 'Signup',
  data() {
    return {
      showPwd: false,
      username: '',
      password1: '',
      password2: '',
      msg: '',
      disabled: true,
      isCheckingExist: false,
      isLoadingSignup: false,
      isValid: false,
      rulesUsername: [
        validateRequired,
        validateEmail
      ]
    }
  },

  computed: {
    disableSignup() {
      return this.disabled || !this.password1 || !this.password2
    }
  },

  watch: {
    '$route.params.lang'(newVal, oldVal) {
      if (newVal !== oldVal && this.$refs.form) {
        this.$refs.form.validate()
      }
    }
  },

  methods: {
    async checkUser() {
      await this.$nextTick()

      if (!this.username || !this.isValid) return false

      this.msg = ''
      this.disabled = true
      this.isCheckingExist = true
      return apiCommonauthUsers.post({
        username: this.username,
        query: 'exact'
      })
        .then(res => {
          const users = res?.data?.data || []
          const [user] = (Array.isArray(users) && users) || []

          if (user) {
            this.msg = this.$t('page.signup.duplicated')

            return
          }

          this.disabled = false

          return true
        })
        .catch(() => false)
        .finally(() => {
          this.isCheckingExist = false
        })
    },
    async signup() {
      if (this.password1 !== this.password2) {
        this.msg = this.$t('pwd_not_match')
        return
      }
      this.isLoadingSignup = true
      this.msg = ''
      const payload = {
        username: this.username,
        password: this.password1,
        method: 'password'
      }
      return apiCommonauthAdminUsers
        .post(payload)
        .then(res => {
          const result = res?.data?.result

          if (result !== 'success') {
            this.msg = this.$t('page.signup.failed')

            return
          }

          this.$router.push('/login')
        })
        .catch(error => {
          this.msg = this.$t('page.signup.failed')

          if (error.message === 'no such email address') {
            this.msg = this.$t('email_adderess_not_exist')
          }
        })
        .finally(() => {
          this.isLoadingSignup = false
        })
    }
  }
}
</script>

<style scoped lang="scss">
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .form-container {
    width: 29%;
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 10px;

    .v-input.v-text-field {
      border-radius: 6px;
    }
  }

  .logo {
    text-align: center;
    border-style: solid;
    border-width: 0;
    border-color: white;
  }

  .v-btn:not(.v-btn--round).v-size--default {
    padding: 0 5px;
    min-width: 29px;
  }

  .link {
    text-decoration: none;

    &:link {
      color: white;
    }

    &:visited {
      color: white;
    }

    &:hover {
      color: #36b;
    }

    &:active {
      color: #f00;
    }
  }
}

@media screen and (max-width: 1366px) and (min-width: 1025px) {
  .login-container {
    .form-container {
      width: 50%;
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 769px) {
  .login-container {
    .form-container {
      width: 69%;
    }
  }
}

@media screen and (max-width: 768px) and (min-width: 415px) {
  .login-container {
    .form-container {
      width: 95%;
    }
  }
}

@media screen and (max-width: 414px) {
  .login-container {
    .form-container {
      width: 100%;
    }
  }
}
</style>
